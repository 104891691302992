<template>
    <div class="time-counter-container">
        <div class="time-counter">
            <div class="progress-loader">
                <span class="label">{{ $t('modules.exams.assestment.studentInformation.timeLabel') }}</span>
                <v-progress-circular :value="percentage" :color="loaderColor" :size="30" :width="8"></v-progress-circular>
            </div>
            <div class="progress-time data-hj-whitelist" :style="`color: ${loaderColor}`">{{ timeToDisplay }}</div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            assestmentTime: {type: Number, default: 60},
            assestmentType: {
                type: String,
                default: 'minutes',
                validator(value) {
                    return value === 'hours'
                        || value === 'minutes'
                        || value === 'seconds'
                        || value === 'milliseconds';
                }
            }
        },
        data() {
            return {
                time: moment.duration(this.assestmentTime, this.assestmentType).asMilliseconds(),
                percentage: 0,
                initialTime: 0,
                graceTime: false,
                interval: null,
            }
        },
        computed: {
            timeToDisplay() {
                const hours = moment.duration(this.time).hours();
                const minutes = moment.duration(this.time).minutes();
                const seconds = moment.duration(this.time).seconds();
                return hours
                    ? `${(hours < 10) ? '0' : ''}${hours}:${(minutes < 10) ? '0' : ''}${minutes}:${(seconds < 10) ? '0' : ''}${seconds}`
                    : `${(minutes < 10) ? '0' : ''}${minutes}:${(seconds < 10) ? '0' : ''}${seconds}`
            },
            loaderColor() {
                return this.time <= 45000 && this.time > 30000 ? '#F9A825'
                    : (this.time <= 30000 ? '#B71C1C' : '#012169');
            },
            isAdmin() {
                return this.$store.getters['commons/isUserAdmin'];
            },
        },
        methods: {
            countDown() {
                let {time} = this;
                return () => {
                    time -= 1000;
                    this.time = time;
                    const duration = moment.duration(time).asMinutes();

                    if (!this.graceTime) this.percentage = 100 - Math.round((this.initialTime - duration) / this.initialTime * 100);
                    else this.percentage = Math.round((this.initialTime - duration) / this.initialTime * 100);

                    if (!time && this.graceTime) {
                        clearInterval(this.interval);
                        this.$emit('time-over', true);
                    } else if (!time && !this.graceTime) {
                        time = 60000;
                        this.time = time;
                        this.initialTime = moment.duration(time).asMinutes();
                        this.graceTime = true;
                    }

                    this.$emit('current-time', this.time);
                }
            },
            clock() {
                if (!this.initialTime) this.initialTime = moment.duration(this.time).asMinutes()
                if (!this.isAdmin) this.interval = setInterval(this.countDown(), 1000);
            }
        },
        mounted() {
            this.clock();
        },
        beforeDestroy() {
            clearInterval(this.interval)
        }
    }
</script>

<style lang="sass" scoped>
.time-counter-container
    display: flex
    flex-direction: column
    padding-bottom: .5rem

    .time-counter
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center

        .progress-loader
            margin-right: 1rem

            .label
                font-weight: bold
                color: rgba(0, 0, 0, 0.87)
                margin-right: 1rem

        .progress-time
            font-size: 27px
            color: #012169
            font-weight: bold
</style>
